<template>
  <div style="min-height: 85vh">
    <b-overlay
      :show="isLoading"
      style="min-height: 85vh"
    >
      <div class="card card-dark rounded-xl mt-3 text-white">
        <div class="card-header bg-black pt-4">
          <h2 class="mb-0 text-center h4">
            {{ trans(title, {}, 'security') }}
          </h2>
        </div>

        <div class="card-body px-5">
          <p
            v-if="passwordOptional"
            class="py-2"
          >
            {{ trans('registration.fromOAuth', { provider }) }}
          </p>

          <slot name="error" />

          <!-- STEP0 -->
          <validation-observer v-slot="{ invalid, handleSubmit }">
            <form
              method="post"
              :name="formName"
              :action="action"
              :class="cssClass"
              @submit.prevent="handleSubmit"
            >
              <slot />

              <div
                v-if="step == 0 || gonnaSubmit"
                :class="gonnaSubmit ? 'd-none' : ''"
              >
                <div v-if="showConnect">
                  <a
                    href="#"
                    class="btn btn-info btn-lg btn-block"
                    style="margin-top: 10px"
                    onclick="fb_login();"
                  >
                    <i
                      class="fa-brands fa-facebook"
                      aria-hidden="true"
                    ></i>
                    {{ trans('website.register_facebook', {}, 'messages') }}
                  </a>

                  <a
                    v-if="appleRoute"
                    :href="appleRoute"
                    class="btn btn-black btn-lg btn-block rounded-xl"
                  >
                    <i
                      class="fa-brands fa-apple"
                      aria-hidden="true"
                    ></i>
                    {{ trans('website.register_apple', {}, 'messages') }}
                  </a>

                  <a
                    v-if="googleRoute"
                    :href="googleRoute"
                    class="btn btn-primary btn-lg btn-block rounded-xl"
                  >
                    <i
                      class="fa-brands fa-google"
                      aria-hidden="true"
                    ></i>
                    {{ trans('website.register_google', {}, 'messages') }}
                  </a>
                </div>

                <div
                  v-if="showConnect"
                  class="fs-4 mt-4 mb-3 text-center"
                >
                  {{ trans('website.register.title.h2', {}, 'messages') }}
                </div>

                <validation-provider
                  v-slot="validationContext"
                  :name="trans('general.email')"
                  :rules="{ required: true, email: true }"
                >
                  <b-form-group
                    id="registration-step0-group-email"
                    label-for="registration-step0-email"
                    :label="`${trans('voc.email')} *`"
                    class="mb-4"
                  >
                    <b-form-input
                      id="registration-step0-email"
                      v-model="email"
                      type="email"
                      :disabled="isLoading"
                      :name="`${formName}[email]`"
                      :state="getValidationState(validationContext)"
                      placeholder="email@gmail.com"
                    />
                    <b-form-invalid-feedback id="registration-step0-email-live-feedback">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>

                    <slot name="emailerror"></slot>
                  </b-form-group>
                </validation-provider>

                <template v-if="askPassword">
                  <p
                    v-if="passwordOptional"
                    class="pt-3"
                  >
                    {{ trans('registration.passwordOptional') }}
                  </p>

                  <validation-provider
                    v-if="!passwordOptional || !gonnaSubmit || password || passwordConfirmation"
                    v-slot="validationContext"
                    :name="trans('form.password', {}, 'security')"
                    :rules="{
                      required: !(gonnaSubmit && passwordOptional),
                      confirmed: 'confirmation',
                    }"
                  >
                    <b-form-group
                      id="registration-step0-group-password"
                      label-for="registration-step0-password"
                      :label="`${trans('form.password', {}, 'security')} *`"
                      class="mb-4"
                    >
                      <b-form-input
                        id="registration-step0-password"
                        v-model="password"
                        type="password"
                        :disabled="isLoading"
                        :name="`${formName}[plainPassword][first]`"
                        :state="getValidationState(validationContext)"
                        placeholder="************"
                      />
                      <b-form-invalid-feedback id="registration-step0-password-live-feedback">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>

                  <validation-provider
                    v-if="!passwordOptional || !gonnaSubmit || password || passwordConfirmation"
                    v-slot="validationContext"
                    :name="trans('form.password_confirmation', {}, 'security')"
                    vid="confirmation"
                  >
                    <b-form-group
                      id="registration-step0-group-repeat-password"
                      label-for="registration-step0-repeat-password"
                      :label="`${trans('form.password_confirmation', {}, 'security')} *`"
                      class="mb-4"
                    >
                      <b-form-input
                        id="registration-step0-repeat-password"
                        v-model="passwordConfirmation"
                        type="password"
                        :disabled="isLoading"
                        :name="`${formName}[plainPassword][second]`"
                        :state="getValidationState(validationContext)"
                        placeholder="************"
                      />
                      <b-form-invalid-feedback
                        id="registration-step0-repeat-password-live-feedback"
                      >
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </template>
              </div>
              <!-- /STEP0 -->

              <!-- STEP1 -->
              <div
                v-if="step == 1 || gonnaSubmit"
                :class="gonnaSubmit ? 'd-none' : ''"
              >
                <p>{{ trans('website.register.contact_information', {}, 'messages') }}</p>

                <validation-provider
                  v-slot="validationContext"
                  :name="trans('label.firstName', {}, 'form')"
                  :rules="{ required: true }"
                >
                  <b-form-group
                    id="registration-step1-group-firstname"
                    label-for="registration-step1-firstname"
                    :state="getValidationState(validationContext)"
                    :label="`${trans('label.firstName', {}, 'form')} *`"
                    class="mb-4"
                  >
                    <b-form-input
                      id="registration-step1-firstname"
                      v-model="firstName"
                      type="text"
                      :name="`${formName}[profiles][${profileIndex}][firstName]`"
                      autocomplete="firstname"
                      :disabled="isLoading"
                      placeholder="Marie"
                    />
                    <b-form-invalid-feedback id="registration-step0-firstname-live-feedback">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>

                    <slot name="firstnameerror"></slot>
                  </b-form-group>
                </validation-provider>

                <validation-provider
                  v-slot="validationContext"
                  :name="trans('label.lastName', {}, 'form')"
                  :rules="{ required: true }"
                >
                  <b-form-group
                    id="registration-step1-group-lastname"
                    label-for="registration-step1-lastname"
                    :state="getValidationState(validationContext)"
                    :label="`${trans('label.lastName', {}, 'form')} *`"
                    class="mb-4"
                  >
                    <b-form-input
                      id="registration-step1-lastname"
                      v-model="lastName"
                      autocomplete="lastname"
                      :name="`${formName}[profiles][${profileIndex}][lastName]`"
                      type="text"
                      :disabled="isLoading"
                      placeholder="Marie"
                    />
                    <b-form-invalid-feedback id="registration-step0-lastname-live-feedback">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>

                    <slot name="lastnameerror"></slot>
                  </b-form-group>
                </validation-provider>

                <validation-provider
                  v-slot="validationContext"
                  :name="trans('voc.language')"
                  :rules="{ required: true }"
                >
                  <b-form-group
                    id="registration-step1-group-language"
                    label-for="registration-step1-language"
                    :label="`${trans('voc.language')} *`"
                    :state="getValidationState(validationContext)"
                    class="mb-4"
                  >
                    <language-type
                      v-model="language"
                      :name="`${formName}[profiles][${profileIndex}][language]`"
                    />
                    <b-form-invalid-feedback id="registration-step0-language-live-feedback">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>

                    <slot name="langerror"></slot>
                  </b-form-group>
                </validation-provider>

                <b-form-group
                  id="registration-step1-group-phone"
                  label-for="registration-step1-phone"
                  :state="false"
                  :label="`${trans('website.register.phone_number')} *`"
                  class="mb-4"
                >
                  <phone-type
                    id="registration-step1-phone"
                    v-model="tel"
                    class="d-flex"
                    :has-prepend="false"
                    required
                    :name="`${formName}[profiles][${profileIndex}][mobilePhone]`"
                    :disabled="isLoading"
                  />

                  <slot name="phoneerror"></slot>
                </b-form-group>

                <validation-provider
                  v-slot="validationContext"
                  :name="trans('onboarding.go.terms')"
                  :rules="{ required: { allowFalse: false } }"
                >
                  <b-form-group
                    id="registration-step1-group-terms"
                    label-for="registration-step1-terms"
                    :state="false"
                    class="mb-4"
                  >
                    <b-checkbox
                      v-model="termsAccepted"
                      required
                      :disabled="isLoading"
                      :name="`${formName}[termsAccepted]`"
                      variant="primary"
                    >
                      {{ trans('onboarding.go.acceptTerms') }}
                      <a
                        :href="termsUrl"
                        target="_blank"
                      >
                        {{ trans('onboarding.go.acceptTermsLink') }}
                      </a>
                    </b-checkbox>

                    <b-form-invalid-feedback id="registration-step1-terms-live-feedback">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>

                    <slot name="termserror"></slot>
                  </b-form-group>
                </validation-provider>
              </div>
              <!-- /STEP1 -->

              <!-- STEP2 -->
              <div v-if="step == 2">
                <div class="text-center mb-3">
                  {{ trans('website.member.edit.address', {}, 'messages') }}
                </div>

                <p>{{ trans('registration.address_disclamer', {}, 'messages') }}</p>

                <slot name="addresserror"></slot>

                <assisted-address-type
                  v-model="address"
                  legend-class="fs-3 text-white card-dark"
                  :name="`${formName}[profiles][${profileIndex}][address]`"
                  :placeholder="trans('registration.address_placeholder')"
                >
                  <template #description>{{ null }}</template>
                </assisted-address-type>
              </div>
              <!-- /STEP2 -->

              <div>
                <b-button
                  size="lg"
                  variant="primary"
                  class="w-100 mt-3"
                  :disabled="isLoading || (invalid && step !== 2)"
                  @click="handleNext"
                >
                  <span v-if="step == 2">{{ trans('general.confirm') }}</span>
                  <span v-else>{{ trans('general.next') }}</span>
                </b-button>
              </div>

              <div v-if="step === 0 && passwordOptional">
                <b-button
                  size="lg"
                  variant="primary"
                  class="w-100 my-3"
                  :disabled="isLoading"
                  @click="step = 1"
                >
                  {{ trans('general.skip') }}
                  <div class="fs-1">
                    {{
                      trans('registration.skipPassword', {
                        provider,
                      })
                    }}
                  </div>
                </b-button>
              </div>
            </form>
          </validation-observer>
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import AssistedAddressType from './Form/AssistedAddressType.vue';
import LanguageType from './Form/LanguageType.vue';
import PhoneType from './Form/PhoneType.vue';
import { ValidationObserver, ValidationProvider, extend, localize } from 'vee-validate';
import en from 'vee-validate/dist/locale/en.json';
import fr from 'vee-validate/dist/locale/fr.json';
import { confirmed, required, email, between, min, max } from 'vee-validate/dist/rules';
import urlRule from '../../helpers/validator/urlRule';

extend('confirmed', confirmed);
extend('email', email);
extend('required', required);
extend('between', between);
extend('min', min);
extend('max', max);
extend('url', urlRule);
localize('en', en);
localize('fr', fr);

export default {
  name: 'RegistrationForm',
  components: {
    AssistedAddressType,
    LanguageType,
    PhoneType,
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    cssClass: { type: String, required: false, default: null },
    action: { type: String, required: false, default: null },
    provider: { type: String, required: false, default: null },
    googleRoute: { type: String, required: false, default: null },
    appleRoute: { type: String, required: false, default: null },
    title: { type: String, required: false, default: 'registration.title' },
    formName: { type: String, required: false, default: 'registration_form' },
    profileIndex: { type: [String, Number], required: false, default: 0 },
    defaultValues: { type: Object, required: false, default: () => {} },
    askPassword: { type: Boolean, required: false, default: true },
    passwordOptional: { type: Boolean, required: false, default: false },
    showConnect: { type: Boolean, required: false, default: true },
  },
  emits: ['submit'],
  data() {
    return {
      isLoading: true,
      gonnaSubmit: false,
      termsAccepted: false,
      step: this.defaultValues?.email && !this.askPassword ? 1 : 0,

      email: this.defaultValues?.email ?? null,
      password: null,
      passwordConfirmation: null,

      firstName: this.defaultValues?.firstName ?? null,
      lastName: this.defaultValues?.lastName ?? null,
      language: this.defaultValues?.language ?? Translator.locale,
      tel: this.defaultValues?.tel ?? {
        country: null,
        phoneNumber: null,
      },

      address: this.defaultValues?.address ?? {},
    };
  },
  computed: {
    termsUrl() {
      return Routing.generate('website.terms.terms_of_service', { _locale: this.spflocale });
    },
  },
  mounted() {
    this.isLoading = false;
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    handleNext() {
      if (this.step < 2) {
        this.step++;
      } else {
        this.gonnaSubmit = true;
        this.$nextTick(() => {
          document.querySelector(`form[name=${this.formName}]`).submit();
        });
      }
    },
  },
};
</script>
